import "./src/styles/global.scss";
//import "./src/custom.js";
import "./src/custom.css";

export const onInitialClientRender = () => {
  const link = document.createElement('link');
  link.href =
    'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};
